import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatBotService } from './chat-bot.service';
import { ChatBotButtonComponent } from './chat-bot-button.component';
import { ChatBotComponent } from './chat-bot.component';

@Component({
  selector: 'app-chat-bot-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    ChatBotButtonComponent,
    ChatBotComponent
  ],
  template: `
    <app-chat-bot-button></app-chat-bot-button>
    <app-chat-bot *ngIf="isVisible$ | async"></app-chat-bot>
  `
})
export class ChatBotWrapperComponent implements OnInit {
  isVisible$ = this.chatBotService.isVisible$;

  constructor(private chatBotService: ChatBotService) { }

  ngOnInit(): void {
  }
}