import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatBotService } from './chat-bot.service';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-bot-button',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule
  ],
  template: `
    <button 
      *ngIf="isAllowedUser()"
      mat-fab 
      class="chat-button" 
      [matTooltip]="(isVisible ? 'CHAT_BOT.CLOSE_ASSISTANT' : 'CHAT_BOT.CHAT_ASSISTANT') | translate"
      (click)="toggleChatBot()">
      <div class="icon-wrapper" *ngIf="!isVisible">
        <img src="assets/images/icons/chatbot.png" class="chat-icon" alt="chat bot">
      </div>
      <mat-icon *ngIf="isVisible" class="close-icon">close</mat-icon>
    </button>
  `,
  styles: [`
    .chat-button {
      position: fixed;
      bottom: 30px;
      right: 30px;
      z-index: 9997;
      background-color: var(--mat-theme-primary-color, #6a1b9a);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      overflow: hidden;
    }

    .icon-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
    }

    .chat-icon {
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      object-fit: contain;
      transform: scale(1.2);
    }

    .close-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 768px) {
      .chat-button {
        width: 56px;
        height: 56px;
      }
      
      .icon-wrapper {
        padding: 3px;
      }
      
      .chat-icon {
        width: calc(100% + 6px);
        height: calc(100% + 6px);
      }

      .close-icon {
        font-size: 22px;
      }
    }
  `]
})
export class ChatBotButtonComponent implements OnInit, OnDestroy {
  isVisible = false;
  private subscription: Subscription = new Subscription();

  constructor(private chatBotService: ChatBotService) { }

  ngOnInit(): void {
    this.subscription.add(
      this.chatBotService.isVisible$.subscribe(visible => {
        this.isVisible = visible;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleChatBot(): void {
    this.chatBotService.showExpanded();
  }

  isAllowedUser(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('CURRENT_USER'));
    return currentUser && currentUser.email === 'ahmed.fahim@du-apps.com';
  }
}