import { MatPaginatorIntlCro } from './shared/components/paginator/mat-paginator-Intl';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { LoaderService } from './shared/components/loader/loader.service';
import { httpInterceptorProviders } from './shared/interceptors';
import { environment } from 'src/environments/environment';
import { AsyncPipe, DatePipe } from '@angular/common';
import { MessagingService } from './shared/service/messaging.service';
import { appInitializer } from './shared/interceptors/app.initializer';
import { AuthService } from './auth/auth.service';
import { ConnectionServiceModule } from 'ng-connection-service';
import { CanDeactivateGuard } from './shared/guards/candeactivate.guard';
import { ConfirmComponent } from './pages/shopping-customer/customer-categories/confirm/confirm.component';
import { ConfirmMessageComponent } from './shared/components/confirm-message/confirm-message.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { TourAnchorMatMenuDirective, TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { TOUR_ANCHOR_DIRECTIVE_TYPE } from './proxy-tour-anchor.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TourAnchorDirective } from 'ngx-ui-tour-core';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ChatBotWrapperComponent } from './pages/chat-bot/chat-bot-wrapper.component';
import { MatTooltipModule } from '@angular/material/tooltip';


import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent,
    ConfirmComponent,
    ConfirmMessageComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    MatNativeDateModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ConnectionServiceModule,
    TourMatMenuModule.forRoot(),
    TablerIconsModule.pick(TablerIcons),
    MatTooltipModule,
    ChatBotWrapperComponent
  ],
  providers: [
    httpInterceptorProviders,
    MessagingService,
    AsyncPipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: TOUR_ANCHOR_DIRECTIVE_TYPE,
      useValue: TourAnchorMatMenuDirective
    },
    LoaderService,
    CanDeactivateGuard,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
